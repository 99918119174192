import ListRedirectForm from "./ListRedirectForm";

const Home = (props) => {
  return (
    <div>
      <ListRedirectForm />
    </div>
  );
};
export default Home;
